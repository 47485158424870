/*******************************
         Site Overrides
*******************************/



.ui.header {
    font-weight: 400;
}

h5.ui.header{
    font-weight: 400;	
}
.ui.header .sub.header {
}